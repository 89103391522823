import React, { useEffect, useMemo } from "react";
import { graphql } from "gatsby";
// import { withPreview } from "gatsby-source-prismic";
import Seo from "../components/seo";
import SanityTexte from "../components/SanityTexte";

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
      texte: _rawTexte(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Page = ({ data }) => {
  // console.log(data)
  const { slug, seo, title, texte } = data.sanityPage;

  // useEffect(() => {}, [])

  return (
    <div className='page pt-header-height'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-page page-${slug.current}`}
          page={true}
        />
      )}

      <div className='p-sm py-xl md:p-md md:pb-xl'>
        <h1 className='mb-1e uppercase'>{title}</h1>
        <SanityTexte input={texte} />
      </div>
    </div>
  );
};

export default Page;
